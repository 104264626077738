* {
  font-family: 'Poppins', sans-serif !important;
}

body {
  margin: 0;
  background-color: #F0F3FB;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input::-ms-reveal,
input::-ms-clear {
  /* remove eye/clear icons in MS Edge */
  display: none;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.min-height-100-vh {
  min-height: 100vh;
}

.min-height-100-percent {
  min-height: 100%;
}

.height-100-percent {
  height: 100%;
}
